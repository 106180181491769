import { axiosInstance } from "../../network/apis";
import { API } from "../../utils/Constants";

export const createUser = async (userData) => {
  try {
    const { data } = await axiosInstance.post(
      `${API.ENDPOINTS.CREATE_USER}`,
      { ...userData },
      {
        handlerEnabled: false,
      }
    );
    return data?.data;
  } catch (error) {
    console.log(error);
    throw new Error("Something went wrong!");
  }
};

export const loginUser = async (payload) => {
  try {
    const { data } = await axiosInstance.get(
      `${API.ENDPOINTS.LOGIN_USER}?uid=${payload?.uid}`,
      {
        handlerEnabled: false,
      }
    );
    return data?.data?.length ? data?.data?.[data?.data?.length - 1] : null;
  } catch (error) {
    console.log(error);
    throw new Error("Something went wrong!");
  }
};

export const EmailLogin = async (payload) => {
  // console.log("payload in apiiiiiii", payload);
  try {
    const response = await axiosInstance.post(
      `${API.ENDPOINTS.EMAIL_LOGIN_OTP_REQUEST}`,
      {
        email: payload?.email,
      },
      {
        handlerEnabled: false,
      }
    );
    // console.log("responseeeeeeee", response);
    return response;
  } catch (error) {
    console.log(error);
    throw new Error("Something went wrong!");
  }
};

export const EmailOtpVerify = async (payload) => {
  // console.log("payload in apiiiiiii", payload);
  try {
    const response = await axiosInstance.post(
      `${API.ENDPOINTS.EMAIL_OTP_VERIFY}`,
      {
        email: payload?.email,
        otp: payload?.otp,
      },
      {
        handlerEnabled: false,
      }
    );
    // console.log("responseeeeeeee", response);
    return response;
  } catch (error) {
    console.log(error);
    throw new Error("Something went wrong!");
  }
};
